import React, {useEffect, useState} from 'react';
import SignIn from "./SignIn/SignIn";
import {motion} from 'framer-motion';
import s from "./LoginizationSteps.module.css"
import AutenticationStep from "./AutenticationStep/AutenticationStep";
import {NavLink} from "react-router-dom";
import ForgotPassword from "../../components/ForgotPassword/ForgotPassword";
import logo from "../../assets/img/personalAccount/logo_sign-in.svg"
import CustomModalContactContainer from "../../components/ui/CustomModalContactContainer/CustomModalContactContainer";
import ChooseAccountTypeStep from "./ChooseAccountTypeStep/ChooseAccountTypeStep";
import {toHumanDate} from "../../helpers/ConvertDate";
const LoginizationSteps = () => {

    useEffect(() => {
        localStorage.clear()
        localStorage.removeItem('persist:root')
    }, [])

    const [current, setCurrent] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState<boolean | null>(false)
    const [additionalSettings, setAdditionalSettings] = useState({})
    const [AccountsList, setAccountsList] = useState([])
    const [dataForSend, setDataForSend] = useState({
        email: '',
        password: '',
        emailCode: '',
        twoFaCode: '',
        smsCode: '',
        sessionStart: ''
    })

    const steps = [
        {
            content: <SignIn setCurrent={setCurrent}
                             current={current}
                             setLoginData={setDataForSend}
                             dataForSend={dataForSend}
                             setAdditionalSettings={setAdditionalSettings}
            />
        },
        {
            content: <AutenticationStep setCurrent={setCurrent} dataForSend={dataForSend}
                                        setAccountsList={setAccountsList}
                                        setDataForSend={setDataForSend} additionalSettings={additionalSettings}/>
        },
        {
            content: <ChooseAccountTypeStep setCurrent={setCurrent} AccountsList={AccountsList}/>
        },
        {
            content: <ForgotPassword setCurrent={setCurrent}/>
        },
    ];

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <CustomModalContactContainer isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>

            <div className={current === 0 ? s.wrapper_bg : s.wrapper}>

                <div className={s.login_wrapper_content_left}>
                    <div className={s.content_left}>
                        <div className={s.logo_block}>
                            <NavLink to={"/"}>
                                <img src={logo} alt=""/>
                            </NavLink>
                        </div>
                        <div className={s.welcome_block}>
                            Welcome to <br/>
                            LuxPay!
                        </div>
                        <div className={s.welcome_block_mobile}>
                            Welcome to LuxPay!
                        </div>
                    </div>
                </div>
                <div className={s.login_wrapper_content_right}>
                    <div className={s.quest_block}>
                        {/*<img src={quest} alt="" onClick={() => setIsModalOpen(true)}/>*/}
                    </div>
                    <div>{steps[current].content}</div>
                </div>
                <div className={s.footer_text}>
                    All rights reserved © 2024 Lux International Payment System, UAB License No. 81.&nbsp;
                    <a href="https://www.lb.lt/en/sfi-financial-market-participants/uab-lux-international-payment-system" className={s.licence_link}>Bank of Lithuania</a>
                </div>
            </div>
        </motion.div>
    );
};

export default LoginizationSteps;
