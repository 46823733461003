import React, {useEffect, useState} from 'react';
import s from './NavBar.module.css'
import {Link, useLocation, useNavigate} from "react-router-dom";
import classnames from "classnames";
import closePopup from "../../../assets/img/close.svg"
import logo from "../../../assets/img/logo_without_text.svg"
import TransparentButton from "../../ui/TransparentButton/TransparentButton";
import MyBtn from "../../ui/MyBtn/MyBtn";
import {
    ABOUT,
    BUSINESS_ACCOUNT,
    CONTACT_US,
    FAQ,
    PERSONAL_ACCOUNT,
    AGENT_PROGRAM
} from "../../../Constants/RoutesConstants";
import SideMenu from "../../SideMenu/SideMenu";
import {LogoutThunk} from "../../../store/reducers/ActionCreators";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {calculateCurrentDate} from "../../../helpers/CalculateDate";

type propsType = {
    isActive: boolean
    setIsActive: (boolean: boolean) => void
}
const NavBar = ({isActive, setIsActive}: propsType) => {

    const [IsPersonalSideMenu, setIsPersonalSideMenu] = useState(false)
    const [isMenuShow, setIsMenuShow] = useState<boolean | null>(false)
    const location = useLocation()
    const sessionId = useAppSelector(store => store.userReducer.sessionId)
    const userToken = useAppSelector(store => store.userReducer.userToken)
    const kycStatus = useAppSelector(state => state.userReducer.user.flags.kycStatus)
    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname.includes("personal_account")) {
            setIsPersonalSideMenu(true)
        } else {
            setIsPersonalSideMenu(false)
        }
    }, [location.pathname])

    useEffect(() => {
        if (!kycStatus) {
            setIsMenuShow(false)
        } else if (kycStatus === "Created") {
            setIsMenuShow(false)
        }
        else if (kycStatus === "Processed") {
            setIsMenuShow(false)
        }
        else if (kycStatus === "Rejected") {
            setIsMenuShow(false)
        } else {
            setIsMenuShow(true)
        }
    }, [kycStatus])

    const closeBurgerMenu = () => {
        setIsActive(false);
    };

    const handleLogout = () => {
        dispatch(LogoutThunk(sessionId, calculateCurrentDate(), userToken))
            .then(() => {
                navigate("/login");
            })
    }

    return (
        <>
            {
                IsPersonalSideMenu
                    ? <div className={classnames(s.myPopup_personal, isActive ? s.active_personal : s.inactive_personal)}>
                        <SideMenu isMenuShow={isMenuShow} isSide setIsActive={setIsActive} handleLogout={handleLogout}/>
                    </div>
                    : <div className={classnames(s.myPopup, isActive ? s.active : s.inactive)}>
                        <div className={s.close_img_block}>
                            <Link to="/" onClick={closeBurgerMenu}>
                                <img src={logo} alt="" className={s.mobile_logo}/>
                            </Link>
                            <img src={closePopup} className={s.closeImg} alt="" onClick={() => setIsActive(false)}/>
                        </div>
                        <div className={s.links_block}>
                            <Link className={s.link} to={PERSONAL_ACCOUNT} onClick={closeBurgerMenu}>Personal</Link>
                            <Link className={s.link} to={BUSINESS_ACCOUNT} onClick={closeBurgerMenu}>Business</Link>
                            <Link className={s.link} to={ABOUT} onClick={closeBurgerMenu}>About</Link>
                            <Link className={s.link} to={FAQ} onClick={closeBurgerMenu}>FAQ</Link>
                            <Link className={s.link} to={AGENT_PROGRAM} onClick={closeBurgerMenu}>Agent Program</Link>
                            <Link className={s.link} to={CONTACT_US} onClick={closeBurgerMenu}>Contact Us</Link>
                        </div>
                        <div className={s.buttons_block}>
                            <Link to={"/login"}>
                                <TransparentButton title={"Sign In"}/>
                            </Link>

                            <Link to={"/sign_up"}>
                                <MyBtn title={"Open account"} style={{width: "180px"}}/>
                            </Link>
                        </div>
                    </div>
            }
        </>
    );
};

export default NavBar;
