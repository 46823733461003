import React, {useState, useEffect} from 'react';
import {Formik} from "formik";
import {motion} from 'framer-motion';
import * as Yup from 'yup';
import s from './main-info.module.css';
import MyBtn from '../../../components/ui/MyBtn/MyBtn';
import MyInput from '../../../components/ui/MyInput/MyInput';
import PhoneCodesInput from '../../../components/ui/PhoneCodesInput/PhoneCodesInput';
import {useAppDispatch} from "../../../hooks/redux";
import {checkAvailableEmailThunk} from "../../../store/reducers/ActionCreators";
import attention from '../../../assets/img/attention.svg'

interface RegistrationData {
    firstName: string;
    lastName: string;
    companyName: string;
    email: string;
    phoneNumber: string;
}

interface commonRegistrationInterface {
    setIsEmailInUse: React.Dispatch<React.SetStateAction<boolean | null>>
    values: RegistrationData
}

type PropsType = {
    onSubmit: () => void
}

const validationPersonalSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    phoneNumber: Yup.string()
        .matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){0,20}(\s*)?$/, "Must be only digits")
        .min(6, "min 6")
        .max(20, "max 20")
        .required('Phone number is required'),
});

const validationBusinessSchema = Yup.object({
    companyName: Yup.string(),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    phoneNumber: Yup.string()
        .matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){0,20}(\s*)?$/, "Must be only digits")
        .min(6, "min 6")
        .max(20, "max 20")
        .required('Phone number is required'),
});

const RegistrationFormikHelper = (props: commonRegistrationInterface ) => {

    useEffect(() => {
        props.setIsEmailInUse(false)
    }, [props.values]);

    return null
}

const MainInfoStep: React.FC<PropsType> = ({...props}) => {

    const dispatch = useAppDispatch()

    function getDefaultInitialValues(): RegistrationData {
        return {
            companyName: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
        };
    }

    const localData = localStorage.getItem('registrationData');

    const initialValues: RegistrationData = localData ? JSON.parse(localData) as RegistrationData : getDefaultInitialValues();

    const [accountType, setAccountType] = useState<string | null>(null)
    const [isEmailInUse, setIsEmailInUse] = useState<boolean | null>(false)

    useEffect(() => {
        if (localData) {
            const parsedData = JSON.parse(localData);
            setAccountType(parsedData.type);
        }
    }, [localData]);

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <div className={s.info}>
                <div className={s.title}>
                    Registration Form

                    <div className={s.error_wrapper}>
                        {
                            isEmailInUse
                                ? <div className={s.error_block}>
                                    <img src={attention} alt=""/>
                                    <div>
                                        Email already exists
                                    </div>
                                </div>
                                : <></>
                        }
                    </div>

                </div>

                <Formik
                    initialValues={initialValues}
                    validationSchema={accountType === 'business' ? validationBusinessSchema : validationPersonalSchema}
                    onSubmit={(values) => {

                        dispatch(checkAvailableEmailThunk(values.email))
                            .then((res) => {
                                if (res.data && res.data === 'Email is valid') {

                                    const savedData = JSON.parse(localStorage.getItem('registrationData') || '{}');

                                    const filledData = {
                                        ...savedData,
                                        ...values,
                                    };

                                    localStorage.setItem('registrationData', JSON.stringify(filledData));

                                    props.onSubmit();
                                } else if (res.response.data.includes('is already in use')) {
                                    setIsEmailInUse(true)
                                    console.log("already in use");
                                }
                            })
                    }}
                >

                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit
                      }) => (

                        <form className={s.form} onSubmit={handleSubmit}>
                            {accountType === 'business' ? (
                                <>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            Company Name
                                        </div>
                                        <MyInput
                                            name="companyName"
                                            value={values.companyName}
                                            onChange={handleChange}
                                            isError={errors.companyName}
                                            touched={touched.companyName}
                                            width={'100%'}
                                        />
                                        {touched.companyName && errors.companyName ? (
                                            <div className={s.error}>{errors.companyName}</div>
                                        ) : null}
                                    </div>

                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            email
                                        </div>
                                        <MyInput
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            isError={errors.email}
                                            touched={touched.email}
                                            width={'100%'}
                                        />
                                        {touched.email && errors.email ? (
                                            <div className={s.error}>{errors.email}</div>
                                        ) : null}
                                    </div>

                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            Phone number
                                        </div>
                                        <PhoneCodesInput
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            error={errors.phoneNumber}
                                            touched={touched.phoneNumber}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            first name
                                        </div>
                                        <MyInput
                                            name="firstName"
                                            value={values.firstName}
                                            onChange={handleChange}
                                            isError={errors.firstName}
                                            touched={touched.firstName}
                                            width={'100%'}
                                        />
                                        {touched.firstName && errors.firstName ? (
                                            <div className={s.error}>{errors.firstName}</div>
                                        ) : null}
                                    </div>

                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            last name
                                        </div>
                                        <MyInput
                                            name="lastName"
                                            value={values.lastName}
                                            onChange={handleChange}
                                            isError={errors.lastName}
                                            touched={touched.lastName}
                                            width={'100%'}
                                        />
                                        {touched.lastName && errors.lastName ? (
                                            <div className={s.error}>{errors.lastName}</div>
                                        ) : null}
                                    </div>

                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            email
                                        </div>
                                        <MyInput
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            isError={errors.email}
                                            touched={touched.email}
                                            width={'100%'}
                                        />
                                        {touched.email && errors.email ? (
                                            <div className={s.error}>{errors.email}</div>
                                        ) : null}
                                    </div>

                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            Phone number
                                        </div>
                                        <PhoneCodesInput
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            error={errors.phoneNumber}
                                            touched={touched.phoneNumber}
                                        />
                                    </div>
                                </>
                            )}
                            <MyBtn title={'Next'}
                                   type="submit"
                                   large
                                   isPersonalAccountBtn
                            />

                            <RegistrationFormikHelper values={values} setIsEmailInUse={setIsEmailInUse}/>

                        </form>
                    )}
                </Formik>
            </div>
        </motion.div>
    );
};

export default MainInfoStep;
