import React, {useEffect, useState} from 'react';
import s from "../Security.module.css";
import {Switch} from "antd";
import MyBtn from "../../../../components/ui/MyBtn/MyBtn";
import {ReactComponent as Check} from "../../../../assets/img/personalAccount/checked.svg";
import {ReactComponent as Cross} from "../../../../assets/img/personalAccount/cross.svg";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {
    sendNewWithdrawalSettings,
    sendVerifyCodeToEmailForChangeSettingsThunk,
    sendWithdrawalPinCodeThunk
} from "../../../../store/reducers/ActionCreators";
import CustomModal from "../../../../components/ui/CustomModal/CustomModal";
import iconCheck from "../../../../assets/img/personalAccount/tick-circle.svg";
import styles from "../../../../styles/styles.module.css"
import iconInfo from "../../../../assets/img/personalAccount/info-circle.svg";
import MyInput from "../../../../components/ui/MyInput/MyInput";
import TransparentButton from "../../../../components/ui/TransparentButton/TransparentButton";
import {FormikHelpers, useFormik} from "formik";
import * as yup from "yup";

type propsType = {
    isMobile: boolean
}

const validationSchema = yup.object({
    emailCode: yup.string()
        .required("Required field")
        .matches(/^[0-9/,]+$/, "Must be only digits")
        .max(6, "Only 6 digits")
        .min(6, "Only 6 digits")
})

const WithdrawalSettings = (props: propsType) => {

    const dispatch = useAppDispatch();
    const verifyWithdrawal: boolean | undefined = useAppSelector(state => state.userReducer.user.flags.verifyWithdrawal)
    const blockWithdrawal: boolean | undefined = useAppSelector(state => state.userReducer.user.flags.blockWithdrawal)
    const token: string = useAppSelector(state => state.userReducer.userToken)
    const accountId = useAppSelector(state => state.userReducer.chosenAccountTypeId)
    const userEmail: string | null = useAppSelector(state => state.userReducer.user.userData.email)

    const [settings, setSettings] = useState<any>({verifyWithdrawal, blockWithdrawal})
    const [isSettingsSaveSuccessfully, setIsSettingsSaveSuccessfully] = useState<boolean | null>(null)
    const [isVerifyChangeWithdrawal, setIsVerifyChangeWithdrawal] = useState<boolean | null>(null)

    const changeWithdrawalFormik = useFormik({
        initialValues: {
            emailCode: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {
            sendNewSettings(values.emailCode, formikHelpers)
        },
    })

    const sendNewSettings = (emailCode: string, formikHelpers: FormikHelpers<{emailCode: string }>) => {

        const settingsWithCode = {...settings, emailCode: emailCode}

        dispatch(sendNewWithdrawalSettings(token, settingsWithCode, accountId))
            .then((res) => {
                if (res.data && res.data === "Updated") {
                    setIsSettingsSaveSuccessfully(true)
                    setIsVerifyChangeWithdrawal(false)
                    formikHelpers.resetForm()
                } else {
                    setIsSettingsSaveSuccessfully(false)
                }
            })
    }

    const closeModal = () => {
        setIsSettingsSaveSuccessfully(false)
    }

    const verifyWithdrawalHandleChange = () => {
        if (blockWithdrawal) return
        dispatch(sendVerifyCodeToEmailForChangeSettingsThunk(token))
            .then((res) => {
                console.log(res)
                setIsVerifyChangeWithdrawal(true)
                setSettings({...settings, verifyWithdrawal: !settings.verifyWithdrawal});
            })
    }

    const blockWithdrawalHandleChange = () => {
        if (verifyWithdrawal) return
        dispatch(sendVerifyCodeToEmailForChangeSettingsThunk(token))
            .then((res) => {
                setIsVerifyChangeWithdrawal(true)
                setSettings({...settings, blockWithdrawal: !settings.blockWithdrawal});
            })
    }

    return (
        <div>

            <CustomModal isModalOpen={isVerifyChangeWithdrawal} type={"info"}>
                <form onSubmit={changeWithdrawalFormik.handleSubmit}>
                    <div className={styles.modal_content}>
                        <img src={iconInfo} alt="" className={styles.popup_icon}/>
                        <div className={styles.popup_title}>
                            <div className={s.popup_text_block}>
                                Email authentication
                            </div>
                        </div>
                        <div className={styles.popup_subtitle}>
                            <div className={s.popup_text_block}>
                                Email with confirmation code was sent to email address {userEmail}
                            </div>
                        </div>
                        <div className={styles.input_wrapper}>
                            <span className={styles.input_label}>Confirmation Code</span>
                            <MyInput
                                name={'emailCode'}
                                id={'emailCode'}
                                touched={changeWithdrawalFormik.touched.emailCode}
                                isError={changeWithdrawalFormik.errors.emailCode}
                                onChange={changeWithdrawalFormik.handleChange}
                            />
                            {changeWithdrawalFormik.errors.emailCode && <div className={styles.error_text}>
                                {changeWithdrawalFormik.errors.emailCode}
                            </div>}
                        </div>
                        <div className={s.buttonWrapper}>
                            <div className={styles.popup_button_block}>
                                <TransparentButton
                                    isPersonalAccountBtn
                                    title={"Cancel"}
                                    onClick={() => setIsVerifyChangeWithdrawal(false)}
                                    medium
                                    buttonType={"info"}
                                />
                            </div>
                            <div className={styles.popup_button_block}>
                                <MyBtn
                                    isPersonalAccountBtn
                                    title={"Confirm"}
                                    type={"submit"}
                                    buttonType={"info"}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </CustomModal>

            <CustomModal isModalOpen={isSettingsSaveSuccessfully} type={"confirm"}>
                <div className={styles.modal_content}>
                    <img src={iconCheck} alt="" className={styles.popup_icon}/>
                    <div className={styles.popup_title}>
                        Success!
                    </div>
                    <div className={styles.popup_subtitle}>
                        Your changes have been saved.
                    </div>
                    <div className={styles.popup_button_block}>
                        <MyBtn isPersonalAccountBtn title={"Ok"} onClick={() => closeModal()}/>
                    </div>
                </div>
            </CustomModal>

            <div className={s.autentication_list_block}>
                <div className={s.autentication_item}>
                    <div className={s.row}>
                            <span
                                className={s.autentication_title}>Verify IP addresses before confirming withdrawals</span>
                        <Switch
                            rootClassName={s.my_root_switch}
                            className={s.my_switch}
                            checked={verifyWithdrawal}
                            onChange={() => {
                                verifyWithdrawalHandleChange()
                            }}
                            checkedChildren={<Check style={verifyWithdrawal ? {
                                position: 'relative',
                                left: 19,
                                top: props.isMobile ? 5 : 2
                            } : {position: 'relative', left: 19, top: 100}}/>}
                            unCheckedChildren={<Cross style={verifyWithdrawal ? {
                                position: 'relative',
                                left: 19.5,
                                top: 100
                            } : {
                                position: 'relative',
                                right: props.isMobile ? 19 : 20,
                                top: props.isMobile ? 12 : -1
                            }}/>}
                        />
                    </div>
                    <div className={s.autentication_item_description}>
                        If a withdrawal is requested from a new IP address, you will receive an email to verify and
                        confirm the operation. The "distrust" period for an IP address
                        lasts 24 hours. If the withdrawal is requested 24 hours or more after the change of IP
                        address, no additional verification with the letter is required.

                    </div>
                </div>

                <div className={s.autentication_item}>
                    <div className={s.row}>
                            <span className={s.autentication_title}>Block withdrawals for 24 hours when using a new IP
                                address</span>
                        <Switch
                            rootClassName={s.my_root_switch}
                            className={s.my_switch}
                            checked={blockWithdrawal}
                            onChange={() => {
                                blockWithdrawalHandleChange()
                            }}
                            checkedChildren={<Check style={blockWithdrawal ? {
                                position: 'relative',
                                left: 17,
                                top: props.isMobile ? 5 : 2
                            } : {position: 'relative', left: 19, top: 100}}/>}
                            unCheckedChildren={<Cross style={blockWithdrawal ? {
                                position: 'relative',
                                left: 19,
                                top: 100
                            } : {
                                position: 'relative',
                                right: props.isMobile ? 19 : 18,
                                top: props.isMobile ? 12 : -1
                            }}/>}

                        />
                    </div>
                    <div className={s.autentication_item_description}>
                        When you log into your account from a new IP address, all withdrawals will be blocked for 24
                        hours, and you will receive an email notification.
                    </div>
                </div>

                <div className={s.notify}>
                    **Only one of the functions can be active, the activity of two functions at the same time is not
                    allowed.
                </div>

            </div>
        </div>
    );
};

export default WithdrawalSettings;
